@import url(https://fonts.googleapis.com/css?family=Baloo+2&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.navbar {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: fixed;
  top: 0;
  width: 100%;
  background: black;
  height:80px;
  color: white;
  z-index: 900;
  font-size:24px;

}

.navbar a {
  padding: 10px;
  margin: 0 10px;
  cursor: pointer;
  text-decoration: underline;
}
.navbar button{
  padding: 10px;
  margin-left: auto;
  cursor: pointer;
  background: none; /* To remove default button background */
  border: none; /* To remove default button border */
  color: inherit; /* To inherit color from parent */
  font: inherit; /* To inherit font from parent */
}
.description{
  padding: 20px;
  margin: 10px;
}

.section {
  display:flex;
  flex-direction:column;
  align-items: flex-start;
  padding: 20px;
  margin-top:0px;
  background-color:black;
  color: white;
  font-size:24px;
}
.section-title{
margin-bottom:20px;
font-size:40px;
}

.logo {
  height: 50px;
  width: auto; 
  margin-right: auto;
  padding-left:20px;
}

body{
    font-family:"Baloo 2", cursive;
    background:black;
}
.gradient-text{
    white-space: pre-wrap;
    padding:50px;
    padding-top:25px;
    line-height:120px;
   font-size: 120px; 
  font-weight: 900;
  color: transparent;
  background-image: linear-gradient( 145deg, #ffffff,#680CFF,#904BFF); 
  -webkit-background-clip: text; /* For Chrome/Safari */
  background-clip: text;
  /* Fallback for browsers that don't support background-clip: text; */
  display: inline-block; /* Required for background-clip to work */
  -webkit-text-fill-color: transparent;
  min-height:300px;

}
.cursor {
    color:transparent; /* Chrome, Safari, and Opera */
    animation: blink-cursor 1s infinite;
}

/* Chrome, Safari, and Opera */

@keyframes blink-cursor {
    from, to { opacity: 0; }
    50% { opacity: 1; }
}



.firstWord{
    color : #C09CFF;
    font-weight: bold;
}


/*Expertise section*/
.flex-items-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  grid-gap: 10px;
  gap: 10px;
}

.flex-item-element {
  flex: 1 1 30%;
  padding: 20px;
  margin: 10px;
  box-sizing: border-box;
  text-align: center;
}



/*about section
 */

.text-content {
    flex: 1 1 45%;
    max-width: 45%;
    padding: 25px;
}
.image-content{
  padding:25px;
  
}

.image-wrapper {
  display: inline-block;
  position: relative; /* Needed for absolute positioning of pseudo-element */
}

.image-wrapper img {
  display: block;
  border: 30px solid black; /* Black border */
  position:relative;
  z-index:10;
  max-width:712px;
}
.test{
 margin:30px; 
}

.image-wrapper::before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  width: 250px; /* desired width of your top right decoration */
  height: 250px; /* desired height of your top right decoration */
  background-image: linear-gradient(#9D88BE, #680CFF);
  background-repeat: no-repeat;
  z-index: 0;
}
/*contact us */
.contact-button {
  display: block;
  width: 60%;
  margin: 0 auto;
  padding: 10px 20px;
  background: linear-gradient(to right, #FFFFFF, #680CFF); /* Example gradient for the background */
  color: transparent; /* Make the text transparent to see the background */
  background-clip: text; /* Clip the background to the text */
  -webkit-background-clip: text; /* For WebKit/Blink browsers */
  -webkit-text-fill-color: transparent; /* Transparent text fill for WebKit/Blink browsers */
  text-align: center;
  text-decoration: none;
  font-weight: bold;
  border-radius: 40px;
  cursor: pointer;
  border: 2px solid;
  border-color: #9C7DCD; /* Gradient border */
  transition: background-color 0.3s;
  font-size: 40px;
}

.contact-button:hover {
  transform: scale(1.05);
}



/*Burger menu*/

.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  left: 20px;
  top: 20px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #B291ED;
}


/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 50px;
  width: 50px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}


/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}

@media screen and (max-width: 900px) {
  .logo{
      position: fixed;
  left: 56px;
  top: 10px;
      
    }
    
        .navbar{
        height:80px;
        margin-top:0px;
        display:block;

    }
    .image-wrapper img {
        max-width: calc(100% - 60px);  /* Maximum width of image is 100% of its container */
        height: auto;     /* Height is adjusted automatically to maintain aspect ratio */
    }
}


@media screen and (max-width: 768px) {
    .text-content,
    .image-content {
        flex: 1 1 100%;
        max-width: 100%;
        
    }
    text-content{
     padding:25px; 
    }

    .image-content {
        order: 1; /* This will make the image appear above the text content */
        padding: 10px;
    }
    .image-wrapper{
      max-width:80%
    }
    .image-wrapper img {
        max-width: calc(100% - 60px);  /* Maximum width of image is 100% of its container */
        height: auto;     /* Height is adjusted automatically to maintain aspect ratio */
    }
    .image-wrapper::before{
      width:50%;
      height:50%;
      
    }

    .flex-item-element {
    flex: 1 1 45%;
  }
  .gradient-text {
        font-size: 80px; /* Reduced font size */
        line-height: 80px; /* Adjust line height accordingly */
        padding: 25px; /* Adjust padding if necessary */
        padding-top: 12px;
        height:550px;
    }
    
}



@media screen and (max-width: 500px) {

    .image-wrapper img {
        max-width: calc(100% - 40px);  /* Maximum width of image is 100% of its container */
        height: auto;     /* Height is adjusted automatically to maintain aspect ratio */
    }

    .logo{
      max-width:300px;
    }

    .navbar button{
   margin :0 10px;
}

    .test{
      margin:20px; 
    }
    .image-wrapper img {
      border: 20px solid black; /* Black border */

    }   
    .contact-button {
      font-size:25px;
      border-radius:25px;
    }

    .flex-item-element {
    flex: 1 1 100%;
  }
  .gradient-text {
        font-size: 40px; 
        line-height: 50px; 
        padding: 25px; 
        padding-top: 12px;
        height:400px;
    }
    
}





